import { useState } from "react";
import { Box, Grid, Spinner } from "@storyofams/react-ui";

import { createClient } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import { MenuAccordion } from "./MenuAccordion";
import { NavigationLinkNew } from "./NavigationLinkNew";
import useMediaQuery from "~hooks/useMediaQuery";

export const RecipesCategoriesItems = ({ close }) => {
  const matchesMobile: boolean = useMediaQuery("(max-width: 769px)");
  const [openItem, setOpenItem] = useState(matchesMobile ? null : 0);
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getRecipeCategories = async (): Promise<any> => {
    try {
      let query = supabase.from("recipe_category").select("*");
      const { data, error } = await query;
      if (error) return [];
      return Object.entries(
        //@ts-ignore
        Object.groupBy(data, ({ utility_type }) => utility_type)
      ).sort((a, b) =>
        a[0] === "Haar" && (b[0] === "Thuis" || b[0] === "Overig") ? -1 : 0
      );
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const { data: recipeCategories, isLoading } = useQuery(
    ["getRecipeCategories"],
    getRecipeCategories,
    {
      enabled: true,
    }
  );
  if (!recipeCategories && !recipeCategories?.length) return null;
  if (isLoading) return <Spinner />;
  return (
    <Box borderTop={"1px solid grey200"}>
      {recipeCategories.map(([title, items], i) => (
        <Box
          key={title}
          onClick={(e) => {
            if (i === openItem) setOpenItem(null);
            else setOpenItem(i);
            e.stopPropagation();
          }}
        >
          <MenuAccordion key={title} title={title} IsOpen={i === openItem}>
            <Grid rowSize={[1, 2]} rowGap={[0, 1.5]}>
              {items
                .sort((a, b) => (a.category_name > b.category_name ? 1 : -1))
                .map(({ category_name, id }, i) => (
                  <Box
                    key={category_name}
                    mb={[i !== items.length - 1 ? 2 : 0, 0]}
                  >
                    <NavigationLinkNew
                      textAlign="left"
                      link={`/recipes?category=${id}`}
                      fontSize={"14px"}
                      lineHeight={"19.6px"}
                      onClick={() => {
                        close();
                        // posthog.capture("Clicked Menu", {
                        //   tab: "Recepten",
                        //   item: item.title,
                        // });
                      }}
                    >
                      {category_name}
                    </NavigationLinkNew>
                  </Box>
                ))}
            </Grid>
          </MenuAccordion>
        </Box>
      ))}
    </Box>
  );
};
